import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";

// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {APIRequest} from "utils/ApiRequest";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";

function AdminPage() {
  const [modalLarge, setModalLarge] = React.useState(false);
  const [orders, setOrders] = React.useState(null);
  const [successOrders, setSuccessOrders] = React.useState(null);
  const [successDPKPNCOrders, setDBKPNCSuccessOrders] = React.useState(null);
  const [successDPKPCOrders, setDBKPCSuccessOrders] = React.useState(null);
  const [searchOrders, setSearchOrders] = React.useState(null);
  const history = useNavigate();
  const [token, setToken] = React.useState(null);
  const [plainTabs, setPlainTabs] = React.useState("1");
  const [filteredOrder, setFilteredOrder] = React.useState(null);
  const [filteredOrderN, setFilteredOrderN] = React.useState(null);
  const [filteredOrderS, setFilteredOrderS] = React.useState(null);
  const [modalLargeN, setModalLargeN] = React.useState(false);
  const [modalLargeS, setModalLargeS] = React.useState(false);
  const [selectedUserExInfo, setSeletedUserExInfo] = useState(null);
  const [mobileOrEmail, setMobileOrEmail] = useState(null);
  const [mobileOrEmailFocus, setMobileOrEmailFocus] = React.useState(false);
  const [, setDPKPCount] = React.useState(0);
  const [orderTSCursor, setOrderTSCursor] = React.useState(1000000000000000000);
  const [orderTSCursorN, setOrderTSCursorN] = React.useState(1000000000000000000);
  const [ncTotal, setNCTotal] = React.useState(0);
  const [cTotal, setCTotal] = React.useState(0);
  const [allProducts, setAllProducts] = useState([]);
  const [membership, setMembership] = useState(false);
  const [sponsorship, setSponsorship] = useState(false);
  const [dailyMembership, setDailyPass] = useState(false);
  const [coSponsorPrice, setCoPrice] = useState(null);
  const [price, setPrice] = useState(null);
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);

  React.useEffect(() => {
    let userInfo = localStorage.getItem('user-info');
    if (userInfo !== null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session != null) {
        if (!userInfo.admin) {
          history("/login");
          return;
        }
        setToken(userInfo.session);
        loadPendingOrderDetails(userInfo.session);
        loadSuccessOrderDetails(userInfo.session);
      } else {
        history("/login")
        return;
      }
    } else {
      history("/login");
      return;
    }
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  async function loadPendingOrderDetails(token) {
    APIRequest(
      token,
      "/getPendingOrders",
      {},
      (res) => {
        setOrders(res.data);
      }
    );
  }

  async function loadSuccessOrderDetails(token) {
    APIRequest(
      token,
      "/getSuccessOrders",
      {},
      (res) => {
        setSuccessOrders(res.data);
      }
    );
  }

  function loadDPKPNotCollectedOrder(token) {
    APIRequest(
      token,
      "/getDPKPNotCollectedOrder",
      { orderTSCursor },
      (res) => {
        if (orderTSCursor === 1000000000000000000) {
          setDBKPNCSuccessOrders(res.data);
        } else {
          setDBKPNCSuccessOrders([...successDPKPNCOrders, ...res.data]);
        }
        if (res.data !== null) {
          let min = orderTSCursor;
          res.data.map(s => {
            if (min > s.orderSuccessTS) {
              min = s.orderSuccessTS;
            }
          });
          let total = ncTotal;
          res.data.map(s => {
            s.selectedProducts.map(sp => {
              if (sp.code === "DPSP") {
                total = total + sp.qty;
              }
            });
          });
          setNCTotal(total);
          setOrderTSCursor(min);
        }
      }
    );
  }

  function loadDPKPCollectedOrder(token) {
    APIRequest(
      token,
      "/getDPKPCollectedOrder",
      { orderTSCursor: orderTSCursorN },
      (res) => {
        if (orderTSCursorN === 1000000000000000000) {
          setDBKPCSuccessOrders(res.data);
        } else {
          setDBKPCSuccessOrders([...successDPKPCOrders, ...res.data]);
        }
        if (res.data !== null) {
          let min = orderTSCursorN;
          res.data.map(s => {
            if (min > s.orderSuccessTS) {
              min = s.orderSuccessTS;
            }
          });
          let total = cTotal;
          res.data.map(s => {
            s.selectedProducts.map(sp => {
              if (sp.code === "DPSP") {
                total = total + sp.qty;
              }
            });
          });
          setCTotal(total);
          setOrderTSCursorN(min);
        }
      }
    );
  }

  async function loadDPKPCount(token) {
    APIRequest(
      token,
      "/getDPKPTotalCount",
      {},
      (res) => {
        setDPKPCount(res.data.count);
      },
      () => {
        setDPKPCount(0);
      }
    );
  }

  const loadSearchOrderDetails = () => {
    APIRequest(
      token,
      "/searchUserOrder",
      { emailId: mobileOrEmail, mobileNo: mobileOrEmail },
      (res) => {
        setSearchOrders(res.data);
      }
    );
  }

  const handleMobileOrEmailChange = (e) => {
    setMobileOrEmail(e.target.value);
  };

  const handleOrderFetch = (orderUserId, orderRcptId) => {
    if (orders === null) return;
    let filteredOrder = orders.filter((order) => order.orderRcptId === orderRcptId && order.offlineUserId === orderUserId);
    console.log(filteredOrder);
    setFilteredOrder(filteredOrder);
    setModalLarge(true);
  }

  const handleSuccessOrderFetch = (orderUserId, orderRcptId) => {
    if (successOrders === null) return;
    let filteredOrder = successOrders.filter((order) => order.orderRcptId === orderRcptId && order.offlineUserId === orderUserId);
    setFilteredOrderN(filteredOrder);
    setModalLargeN(true);
  }

  const handleSearchOrderFetch = (orderUserId, orderRcptId) => {
    if (searchOrders === null) return;
    let filteredOrder = searchOrders.filter((order) => order.orderRcptId === orderRcptId && order.offlineUserId === orderUserId);
    setFilteredOrderS(filteredOrder);
    setModalLargeS(true);
  }


  const handleOfflineApprove = (offlineUserId, orderPkId) => {
    APIRequest(
      token,
      "/approveOfflineOrder",
      { offlineUserId, orderPkId },
      (res) => {
        toast.success("You have approved the contribution successfully!");
        setModalLarge(false);
        setSeletedUserExInfo(null)
        loadPendingOrderDetails(token);
        loadSuccessOrderDetails(token);
      }
    );
  }

  const handleMarkCouponCollected = (userName, userEmail, orderRcptId) => {
    APIRequest(
      token,
      "/markCouponCollected",
      { userName, userEmail, orderRcptId },
      (res) => {
        toast.success("You have approved the contribution successfully!");
        setModalLargeS(false);
        setFilteredOrderS(null);
        setSearchOrders(null);
        loadPendingOrderDetails(token);
        loadSuccessOrderDetails(token);
      }
    );
  }

  const handleOfflineReject = (offlineUserId, orderPkId) => {
    APIRequest(
      token,
      "/rejectOfflineOrder",
      { offlineUserId, orderPkId },
      (res) => {
        toast.success("You have rejected the contribution successfully!");
        setModalLarge(false);
        setSeletedUserExInfo(null);
        loadPendingOrderDetails(token);
      }
    );
  }

  function loadAllProducts(token) {
    APIRequest(
      token,
      "/getUnGropedProducts",
      {},
      (res) => {
        setAllProducts(res.data);
      }
    );
  }

  const handleMembershipChange = (event) => {
    if (event.target.value === "M") {
      setMembership(true);
      setSponsorship(false);
      setDailyPass(false);
    } else if (event.target.value === "S") {
      setMembership(false);
      setSponsorship(true);
      setDailyPass(false);
    } if (event.target.value === "D") {
      setMembership(false);
      setSponsorship(false);
      setDailyPass(true);
    }
  }

  const addProduct = (token) => {
    APIRequest(
      token,
      "/addProduct",
      { code, name, price, coSponsorPrice, membership, sponsorship, dailyMembership },
      (res) => {
        loadAllProducts(token);
        toast.success("Product added successfully!");
      }
    );
  }

  const markProductAvailability = (token, id, available) => {
    APIRequest(
      token,
      "/markProductAvailable",
      { id, available },
      (res) => {
        loadAllProducts(token);
        toast.success("Product availbility updated!");
      }
    );
  }

  const markProductOnlyFullSponsor = (token, id, fullSponsorOnly) => {
    APIRequest(
      token,
      "/markProductFullSponsor",
      { id, fullSponsorOnly },
      (res) => {
        loadAllProducts(token);
        toast.success("Product spnsorship status updated!");
      }
    );
  }



  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div
          className="page-header clear-filter page-header-small"
          filter-color="blue"
        >
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/banner2.jpg") + ")",
            }}
          />
        </div>
        <div className="section">
          <Container>
            <Card className="card-nav-tabs card-plain">
              <CardHeader className="card-header-danger">
                <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <Nav data-tabs="tabs" tabs>
                      {/* <NavItem>
                        <NavLink
                          className={plainTabs === "0" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            //loadDPKPCount(token);
                            setPlainTabs("0");
                          }}
                        >
                          Current Stats
                        </NavLink>
                      </NavItem> */}
                      <NavItem>
                        <NavLink
                          className={plainTabs === "1" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            setPlainTabs("1");
                          }}
                        >
                          Pass Collection
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={plainTabs === "2" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            setPlainTabs("2");
                          }}
                        >
                          Pending Orders
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={plainTabs === "3" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            setPlainTabs("3");
                          }}
                        >
                          Success Orders
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={plainTabs === "4" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            loadDPKPNotCollectedOrder(token);
                            setPlainTabs("4");
                          }}
                        >
                          Not Collected Orders
                        </NavLink>
                      </NavItem>
                      
                      <NavItem>
                        <NavLink
                          className={plainTabs === "5" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            setDBKPCSuccessOrders(null);
                            setOrderTSCursorN(1000000000000000000);
                            setPlainTabs("5");
                          }}
                        >
                          Collected Orders
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={plainTabs === "6" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            setPlainTabs("6");
                          }}
                        >
                          Add Product
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={plainTabs === "7" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            loadAllProducts(token);
                            setPlainTabs("7");
                          }}
                        >
                          All Products
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={plainTabs === "8" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            setPlainTabs("8");
                          }}
                        >
                          Block Product
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <TabContent
                  className="text-center"
                  activeTab={"plainTabs" + plainTabs}
                >
                  <TabPane tabId="plainTabs1">
                    <Card border="primary" className="nav-tabs text-center" outline="true">
                      <CardTitle tag="h5"><b>Search user order</b></CardTitle>
                      <CardBody>
                        <Row className="text-center">
                          <Col md="12">
                            <InputGroup
                              className={
                                "input-lg" +
                                (mobileOrEmailFocus ? " input-group-focus" : "")
                              }
                            >
                              <Input
                                placeholder="* Mobile / Email"
                                type="text"
                                onFocus={() => setMobileOrEmailFocus(true)}
                                onBlur={() => setMobileOrEmailFocus(false)}
                                onChange={handleMobileOrEmailChange}
                              ></Input>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="text-center">
                          <Col md="12">
                            <Button
                              className="btn-round"
                              color="danger"
                              onClick={() => loadSearchOrderDetails()}
                              size="lg"
                            >
                              Search Orders
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>

                    </Card>



                    {searchOrders !== null && searchOrders.length > 0 &&
                      <Table responsive bordered>
                        <thead>
                          <tr className="bg-primary text-white">
                            <td><b>Contribution ID</b></td>
                            <td><b>Amount</b></td>
                            <td><b>Name</b></td>
                            <td><b>Mobile</b></td>
                            <td><b>Status</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          {searchOrders.map((orderS) => (
                            <tr>
                              <td>{orderS.orderRcptId}</td>
                              <td><b>{orderS.orderTotal}</b></td>
                              <td>{orderS.userName}</td>
                              <td>{orderS.userMobile}</td>
                              {orderS.passCollected !== null && orderS.passCollected === 0 &&
                                <td>
                                  <Button className="btn-link" color="primary" onClick={() => handleSearchOrderFetch(orderS.offlineUserId, orderS.orderRcptId)}><b>Click for Details</b></Button>
                                </td>
                              }
                              {orderS.passCollected !== null && orderS.passCollected === 1 &&
                                <td>
                                  Pass Already Collected
                                </td>
                              }


                            </tr>
                          ))
                          }
                        </tbody>
                      </Table>
                    }
                    <Modal
                      isOpen={modalLargeS}
                      className="modal-lg"
                      modalClassName="bd-example-modal-lg"
                    // toggle={() => setModalLargeN(false)}
                    >
                      <ModalHeader>

                        <h5 className="modal-title" id="myLargeModalLabel">
                          <b>Contribution Details</b>
                        </h5>
                        <button
                          aria-label="Close"
                          className="close"
                          type="button"
                          onClick={() => {
                            setModalLargeS(false)
                            selectedUserExInfo(null);
                          }}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        <Table responsive bordered>
                          <thead class="text-white">
                            <tr class="bg-primary">
                              <td><b>Plan Name</b></td>
                              <td><b>Quantity</b></td>
                              <td><b>Contribution</b></td>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredOrderS !== null && filteredOrderS[0] !== null && filteredOrderS[0].selectedProducts !== null && filteredOrderS[0].selectedProducts.map((prd) => (
                              <tr>
                                <td class="text-left">{prd.name}</td>
                                <td>{prd.qty}</td>
                                <td>{prd.sellPrice}</td>
                              </tr>
                            ))
                            }

                            {filteredOrderS !== null && filteredOrderS[0] &&
                              <tr class="bg-secondary text-white">
                                <td colSpan={2} class="text-left"><b>Total</b></td>
                                <td><b>{filteredOrderS[0].orderTotal}</b></td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                        {filteredOrderS !== null && filteredOrderS[0] &&
                          <Table responsive borderless="true">
                            <tbody>
                              {filteredOrderS[0].orderDate !== undefined && filteredOrderS[0].orderDate !== null &&
                                <tr>
                                  <td><b>Order Date</b></td>
                                  <td colSpan={3} class="text-left">{filteredOrderS[0].orderDate}</td>
                                </tr>
                              }
                              <tr>
                                <td><b>Contribution Id</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderS[0].orderRcptId}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Name</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderS[0].userName}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Email</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderS[0].userEmail}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Mobile</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderS[0].userMobile}</td>
                              </tr>
                            </tbody>
                          </Table>
                        }
                      </ModalBody>
                      <ModalFooter className="text-center">
                        {filteredOrderS !== null && filteredOrderS[0] && (filteredOrderS[0].passCollected === null || filteredOrderS[0].passCollected === 0) &&
                          <Row>
                            <Col md="6" className="text-center">
                              <Button className="btn" color="primary" size="lg" onClick={() => handleMarkCouponCollected(filteredOrderS[0].userName, filteredOrderS[0].userEmail, filteredOrderS[0].orderRcptId)}><b>Mark as Collected</b></Button>
                            </Col>
                          </Row>
                        }
                      </ModalFooter>
                    </Modal>
                  </TabPane>

                  <TabPane tabId="plainTabs2">
                    {orders !== null && orders.length > 0 &&
                      <Table responsive bordered>
                        <thead>
                          <tr className="bg-primary text-white">
                            <td><b>Contribution For Year</b></td>
                            <td><b>Contribution ID</b></td>
                            <td><b>Transaction ID</b></td>
                            <td><b>Amount</b></td>
                            <td><b>Name</b></td>
                            <td><b>Mobile</b></td>
                            <td><b>Status</b></td>
                            <td><b>Link</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order) => (
                            <tr>
                              <td>{order.fiscalYear}</td>
                              <td>{order.orderRcptId}</td>
                              <td><b>{order.transactionId}</b></td>
                              <td><b>{order.orderTotal}</b></td>
                              <td>{order.userName}</td>
                              <td>{order.userMobile}</td>
                              <td><b>{order.status}</b></td>
                              <td>
                                <Button className="btn-link" color="primary" onClick={() => handleOrderFetch(order.offlineUserId, order.orderRcptId)}><b>Click for Details</b></Button>
                              </td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </Table>
                    }
                    {(orders === null || orders.length <= 0) &&
                      <Row>
                        <Col>
                          <h4>No contribution is pending for verification</h4>
                        </Col>
                      </Row>
                    }
                    <Modal
                      isOpen={modalLarge}
                      className="modal-lg"
                      modalClassName="bd-example-modal-lg"
                      toggle={() => setModalLarge(false)}
                    >
                      <ModalHeader>

                        <h5 className="modal-title" id="myLargeModalLabel">
                          <b>Pending Contribution Details</b>
                        </h5>
                        <button
                          aria-label="Close"
                          className="close"
                          type="button"
                          onClick={() => setModalLarge(false)}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        <Table responsive bordered>
                          <thead class="text-white">
                            <tr class="bg-primary">
                              <td><b>Plan Name</b></td>
                              <td><b>Quantity</b></td>
                              <td><b>Contribution</b></td>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredOrder !== null && filteredOrder[0] !== null && filteredOrder[0].selectedProducts !== null && filteredOrder[0].selectedProducts.map((prd) => (
                              <tr>
                                <td class="text-left">{prd.name}</td>
                                <td>{prd.qty}</td>
                                <td>{prd.sellPrice}</td>
                              </tr>
                            ))
                            }

                            {filteredOrder !== null && filteredOrder[0] &&
                              <tr class="bg-secondary text-white">
                                <td colSpan={2} class="text-left"><b>Total</b></td>
                                <td><b>{filteredOrder[0].orderTotal}</b></td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                        {filteredOrder !== null && filteredOrder[0] &&
                          <Table responsive bordered>
                            <tbody>
                              <tr>
                                <td><b>Contribution Id</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].orderRcptId}</td>
                              </tr>
                              <tr>
                                <td><b>Contribution Status</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].status}</td>
                              </tr>
                              <tr>
                                <td><b>Transaction ID</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].transactionId}</td>
                              </tr>
                              <tr>
                                <td><b>Bank</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].bankName}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Name</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].userName}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Email</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].userEmail}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Mobile</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].userMobile}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Refer Source</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].referSource}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Referred By</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].referredBy}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Address</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].addr1} - {filteredOrder[0].addr2}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor City</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].city}</td>
                              </tr>
                            </tbody>
                          </Table>
                        }
                      </ModalBody>
                      <ModalFooter className="text-left">
                        {filteredOrder !== null && filteredOrder[0] &&
                          <Row>
                            <Col md="6" className="text-right">
                              <Button className="btn-round text-right" color="primary" onClick={() => handleOfflineApprove(filteredOrder[0].offlineUserId, filteredOrder[0].orderPkId)}><b>Approve</b></Button>
                            </Col>
                            <Col md="6" className="text-left">
                              <Button className="btn-round text-left" color="secondary" onClick={() => handleOfflineReject(filteredOrder[0].offlineUserId, filteredOrder[0].orderPkId)}><b>Reject</b></Button>
                            </Col>
                          </Row>
                        }
                      </ModalFooter>
                    </Modal>
                  </TabPane>

                  <TabPane tabId="plainTabs3">
                    {successOrders !== null && successOrders.length > 0 &&
                      <Table responsive bordered>
                        <thead>
                          <tr className="bg-primary text-white">
                            <td><b>Contribution For Year</b></td>
                            <td><b>Date</b></td>
                            <td><b>Contribution ID</b></td>
                            {/* <td><b>Payment Type</b></td> */}
                            <td><b>Payment Transaction ID</b></td>
                            <td><b>Amount</b></td>
                            <td><b>Name</b></td>
                            <td><b>Mobile</b></td>
                            {/* <td><b>Status</b></td> */}
                            <td><b>Link</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          {successOrders.map((order) => (
                            <tr>
                              <td>{order.fiscalYear}</td>
                              <td>{order.orderDate}</td>
                              <td>{order.orderRcptId}</td>
                              {/* <td>{order.paymentType}</td> */}
                              <td><b>{order.transactionId}</b></td>
                              <td><b>{order.orderTotal}</b></td>
                              <td>{order.userName}</td>
                              <td>{order.userMobile}</td>
                              {/* <td><b>{order.status}</b></td> */}
                              <td>
                                <Button className="btn-link" color="primary" onClick={() => handleSuccessOrderFetch(order.offlineUserId, order.orderRcptId)}><b>Click for Details</b></Button>
                              </td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </Table>
                    }
                    {(successOrders === null || successOrders.length <= 0) &&
                      <Row>
                        <Col>
                          <h4>No contribution is pending for verification</h4>
                        </Col>
                      </Row>
                    }
                    <Modal
                      isOpen={modalLargeN}
                      className="modal-lg"
                      modalClassName="bd-example-modal-lg"
                    // toggle={() => setModalLargeN(false)}
                    >
                      <ModalHeader>

                        <h5 className="modal-title" id="myLargeModalLabel">
                          <b>Contribution Details</b>
                        </h5>
                        <button
                          aria-label="Close"
                          className="close"
                          type="button"
                          onClick={() => {
                            setModalLargeN(false)
                            selectedUserExInfo(null);
                          }}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        <Table responsive bordered>
                          <thead class="text-white">
                            <tr class="bg-primary">
                              <td><b>Plan Name</b></td>
                              <td><b>Quantity</b></td>
                              <td><b>Contribution</b></td>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredOrderN !== null && filteredOrderN[0] !== null && filteredOrderN[0].selectedProducts !== null && filteredOrderN[0].selectedProducts.map((prd) => (
                              <tr>
                                <td class="text-left">{prd.name}</td>
                                <td>{prd.qty}</td>
                                <td>{prd.sellPrice}</td>
                              </tr>
                            ))
                            }

                            {filteredOrderN !== null && filteredOrderN[0] &&
                              <tr class="bg-secondary text-white">
                                <td colSpan={2} class="text-left"><b>Total</b></td>
                                <td><b>{filteredOrderN[0].orderTotal}</b></td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                        {filteredOrderN !== null && filteredOrderN[0] &&
                          <Table responsive borderless="true">
                            <tbody>
                              {filteredOrderN[0].orderDate !== undefined && filteredOrderN[0].orderDate !== null &&
                                <tr>
                                  <td><b>Order Date</b></td>
                                  <td colSpan={3} class="text-left">{filteredOrderN[0].orderDate}</td>
                                </tr>
                              }
                              <tr>
                                <td><b>Contribution Id</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].orderRcptId}</td>
                              </tr>
                              <tr>
                                <td><b>Contribution Status</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].status}</td>
                              </tr>
                              <tr>
                                <td><b>Payment Type</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].paymentType}</td>
                              </tr>
                              <tr>
                                <td><b>Transaction ID</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].transactionId}</td>
                              </tr>
                              <tr>
                                <td><b>Bank</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].bankName}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Name</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].userName}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Email</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].userEmail}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Mobile</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].userMobile}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Refer Source</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].referSource}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Referred By</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].referredBy}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor Address</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].addr1} - {filteredOrderN[0].addr2}</td>
                              </tr>
                              <tr>
                                <td><b>Contributor City</b></td>
                                <td colSpan={3} class="text-left">{filteredOrderN[0].city}</td>
                              </tr>
                            </tbody>
                          </Table>
                        }
                      </ModalBody>
                    </Modal>
                  </TabPane>

                  <TabPane tabId="plainTabs4">
                    {successDPKPNCOrders !== null && successDPKPNCOrders.length > 0 &&
                      <Table responsive bordered>
                        <thead>
                          <tr className="bg-primary text-white">
                            <td><b>Name</b></td>
                            <td><b>Mobile</b></td>
                            <td><b>Amount</b></td>
                            <td><b>Quantity</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          {successDPKPNCOrders.map((order) => (
                            <tr>
                              <td>{order.userName}</td>
                              <td>{order.userMobile}</td>
                              <td><b>{order.orderTotal}</b></td>
                              <td>{order.selectedProducts[0].qty}</td>
                            </tr>
                          ))
                          }
                          <tr className="bg-secondary text-white" >
                            <td colSpan={"3"}><b>Total not Collected</b></td>
                            <td><b>{ncTotal}</b></td>
                          </tr>
                        </tbody>
                      </Table>
                    }
                    <Button
                      className="btn-link text-right"
                      color="primary"
                      onClick={() => loadDPKPNotCollectedOrder(token)}>
                      <b>Load More</b>
                    </Button>
                  </TabPane>

                  <TabPane tabId="plainTabs5">
                    {successDPKPCOrders !== null && successDPKPCOrders.length > 0 &&
                      <Table responsive bordered>
                        <thead>
                          <tr className="bg-primary text-white">
                            <td><b>Name</b></td>
                            <td><b>Mobile</b></td>
                            <td><b>Amount</b></td>
                            <td>Quantity</td>
                          </tr>
                        </thead>
                        <tbody>
                          {successDPKPCOrders.map((order) => (
                            <tr>
                              <td>{order.userName}</td>
                              <td>{order.userMobile}</td>
                              <td><b>{order.orderTotal}</b></td>
                              <td>{order.selectedProducts[0].qty}</td>
                            </tr>
                          ))
                          }
                          <tr className="bg-secondary text-white" >
                            <td colSpan={"3"}><b>Total Collected</b></td>
                            <td><b>{cTotal}</b></td>
                          </tr>
                        </tbody>
                      </Table>
                    }
                    <Button
                      className="btn-link text-right"
                      color="primary"
                      onClick={() => loadDPKPCollectedOrder(token)}>
                      <b>Load More</b>
                    </Button>
                  </TabPane>

                  <TabPane tabId="plainTabs6">
                    <Card border="primary" className="nav-tabs text-center" outline="true">
                      <CardTitle tag="h5"><b>Add / Modify Product</b></CardTitle>
                      <CardBody>
                        <Row className="text-center">
                          <Col md="12">
                            <InputGroup className="input-lg">
                              <select id="seleMem" class="form-control form-control-lg" onChange={handleMembershipChange}>
                                <option value="X">Select Product Type</option>
                                <option value="M">Membership</option>
                                <option value="S">Sponsorship</option>
                                <option value="D">DailyPass</option>
                              </select>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="text-center">
                          <Col md="6">
                            <InputGroup className="input-lg">
                              <Input
                                placeholder="Product Name"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                              ></Input>
                            </InputGroup>
                          </Col>
                          <Col md="6">
                            <InputGroup className="input-lg">
                              <Input
                                placeholder="Product Code (4 Character unique code)"
                                type="text"
                                onChange={(e) => setCode(e.target.value)}
                              ></Input>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="text-center">
                          <Col md="6">
                            <InputGroup className="input-lg">
                              <Input
                                placeholder="Price"
                                type="text"
                                onChange={(e) => setPrice(e.target.value)}
                              ></Input>
                            </InputGroup>
                          </Col>
                          <Col md="6">
                            <InputGroup className="input-lg">
                              <Input
                                placeholder="Co Price (Ignore for full sponsor product)"
                                type="text"
                                onChange={(e) => setCoPrice(e.target.value)}
                              ></Input>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="text-center">
                          <Col md="12">
                            <Button
                              className="btn-round"
                              color="danger"
                              onClick={() => addProduct(token)}
                              size="lg"
                            >
                              Add Product
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>

                  <TabPane tabId="plainTabs7">

                    <Table responsive bordered>
                      <thead>
                        <tr className="bg-primary text-white">
                          <td><b>Product Type</b></td>
                          <td><b>Product Name</b></td>
                          <td><b>Product Code</b></td>
                          <td><b>Full Price</b></td>
                          <td><b>Co-Sponsor / Utsav Price</b></td>
                          <td><b>Show on UI</b></td>
                          <td><b>Only full Sponsor</b></td>
                          <td><b>Sponsored or Block By</b></td>
                          <td><b>Action</b></td>
                        </tr>
                      </thead>
                      {
                        allProducts !== null && allProducts.length > 0 &&
                        <tbody>
                          {allProducts.map((product) => (
                            <tr>
                              <td>{product.type}</td>
                              <td><b>{product.name}</b></td>
                              <td>{product.code}</td>
                              <td>{product.price}</td>
                              <td>{product.coPrice}</td>
                              <td>{product.available ? "Yes" : "No"}</td>
                              <td>{product.type === "SPONSORSHIP" ? product.fullSponsorOnly ? "Yes" : "No" : "Not Applicable"}</td>
                              <td>{(product.sponsoredBy !== undefined ? product.sponsoredBy + " - " : "") + (product.sponsoredByEmail !== undefined ? product.sponsoredByEmail : "")}</td>
                              <td>
                                {
                                  product.available === true &&
                                  <Button className="btn-link" color="primary" onClick={() => markProductAvailability(token, product.id, false)}><b>Disable</b></Button>
                                }
                                {
                                  product.available !== true &&
                                  <Button className="btn-link" color="primary" onClick={() => markProductAvailability(token, product.id, true)}><b>Enable</b></Button>
                                }
                                {
                                  product.type === "SPONSORSHIP" && !product.fullSponsorOnly &&
                                  <Button className="btn-link" color="primary" onClick={() => markProductOnlyFullSponsor(token, product.id, true)}><b>Disable Co-Sponsor</b></Button>
                                }
                                {
                                  product.type === "SPONSORSHIP" && product.fullSponsorOnly &&
                                  <Button className="btn-link" color="primary" onClick={() => markProductOnlyFullSponsor(token, product.id, false)}><b>Enable Co-Sponsor</b></Button>
                                }
                              </td>


                            </tr>
                          ))
                          }
                        </tbody>
                      }
                    </Table>

                  </TabPane>

                  <TabPane tabId="plainTabs8">
                    <Card border="primary" className="nav-tabs text-center" outline="true">
                      <CardTitle tag="h5"><b>Block Sponsorship Product</b></CardTitle>
                      <CardBody>
                        <Row className="text-center">
                          <Col md="12">
                            <InputGroup className="input-lg">

                              <select id="seleMem" class="form-control form-control-lg" onChange={handleMembershipChange}>
                                <option value="X">Select Product</option>
                                {allProducts.map((mprd) => (
                                  <option value={mprd.id}>mprd.name</option>
                                ))}

                              </select>
                            </InputGroup>
                          </Col>
                          <Col md="12">
                            <InputGroup
                              className={
                                "input-lg" +
                                (mobileOrEmailFocus ? " input-group-focus" : "")
                              }
                            >
                              <Input
                                placeholder="* Mobile / Email"
                                type="text"
                                onFocus={() => setMobileOrEmailFocus(true)}
                                onBlur={() => setMobileOrEmailFocus(false)}
                                onChange={handleMobileOrEmailChange}
                              ></Input>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="text-center">
                          <Col md="12">
                            <Button
                              className="btn-round"
                              color="danger"
                              onClick={() => loadSearchOrderDetails()}
                              size="lg"
                            >
                              Search Orders
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>

                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default AdminPage;
